
import { defineComponent } from "vue";
import { IonCol, IonRow, IonGrid } from "@ionic/vue";
export default defineComponent({
  name: "GraphicKey",
  components: {
    IonCol,
    IonRow,
    IonGrid,
  },
});
