import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_content = _resolveComponent("ion-content")

  return (_openBlock(), _createBlock(_component_ion_content, { class: "ion-padding" }, {
    default: _withCtx(() => [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.details, (detail, index) => {
        return (_openBlock(), _createBlock("div", { key: index }, [
          _createTextVNode(_toDisplayString(detail.name) + ": ", 1),
          _createVNode("strong", null, "£" + _toDisplayString(_ctx.numberWithCommas(detail.value.toFixed(2))), 1)
        ]))
      }), 128))
    ]),
    _: 1
  }))
}