export function config() {
  return {
    help: {
      over_70: "There is an additional age allowance if you or your spouse is over 70.",
      salary_income: "Enter the total income from all your jobs, including any part time work.",
      self_employed: "Enter your self-employment income after taking off all the tax deductible expenses, that you have as part of running your business, from the business profits. This might be things like staff costs and materials.",
      pension_income: "Enter the total income from all your pensions.",
      other_income: "Enter the total income that you get from savings, dividends or investments. Enter property income after all tax deductible expenses that you have as part of renting the property. This might be things like re-decoration or repairs.",
      work_expenses: "Enter work expenses that you have to pay yourself. This might include things like protective clothing or some tools if your employer doesn't pay for them. You can't claim for your own expenses like travel or flights.",
      pension_contributions: "Enter the total amount you pay into work pensions or your own private pension plans.",
      dependent_children: "This means a child who lives with you and you have responsibility for. You can claim for a child who is under 16, or a child who is over 16 and receiving full time schooling, up to and including secondary education. You can't claim for a child at university or a child who has left school.",
      child_care: "You can claim for child care payments you make yourself to registered child care providers or accredited nannies for looking after your children. You can claim up to £16,320 for a pre-school child or up to £6,273 for a child up to the age of 12.",
      mortgage_balance: "You can find the balance of your mortgage on your mortgage statement or the interest paid certificate your lender issues at the end of each year.",
      mortgage_interest: "This is the interest that you have been charged by your lender and paid in the year. You can find these amounts on your mortgage statements (make sure you don't include your capital repayments) or the interest paid certificate your lender issues at the end of each year.",
      reduce_child_allowance: "Use this button if you want to reduce the amount of child allowance(s) you claim.",
      independently_taxed: "If you arrived in Jersey or got married or entered into a civil partnership after 31 December 2021, you'll be Independently Taxed. You may also be Independently Taxed if you made an Independent Taxation election for the year.",
      number_of_days: "If you're a seasonal worker, you arrived in Jersey for the first time or if you left Jersey permanently you can change the arrival and departure dates. This will automatically change any allowances to the number of full days, including nights, that you are in Jersey. If you're a seasonal worker, only enter your Jersey income. Changing the date will remove the 'Monthly' or 'Weekly' option for entering your figures.",
      figure_interval: "Select 'Total' to enter your figures for the year. The calculator will use the exact amounts you have entered to work out your annual tax. If you choose 'Monthly' or 'Weekly' the calculator will turn these figures into annual amounts.",
      mortgage_on_your_home: "This allowance can only be claimed if you have been charged and paid interest for your home (principal private residence) in Jersey.",
    }
  }
}