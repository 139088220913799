
import { defineComponent } from "vue";
import { IonCol, IonRow, IonGrid } from "@ionic/vue";

export default defineComponent({
  name: "ResultsTable",
  props: {
    independent: {
      type: Boolean,
      default: false,
    },
    title: String,
    married: Boolean,
    salary_income: {
      type: Object,
      default: function () {
        return { total: 0 };
      },
    },
    total_tax_threshold: {
      type: Object,
      default: function () {
        return { total: 0 };
      },
    },
    work_pension_deductions: {
      type: Object,
      default: function () {
        return { work: 0, pension: 0 };
      },
    },
    other_allowances: {
      type: Number,
      default: 0,
    },
    second_earners: {
      type: Object,
      default: function () {
        return { total: 0 };
      },
    },
    standard_taxable_income: {
      type: Object,
      default: function () {
        return { total: 0 };
      },
    },
    marginal_taxable_income: {
      type: Object,
      default: function () {
        return { total: 0 };
      },
    },
    marginal_relief: {
      type: Number,
      default: 0,
    },
    standard_tax: {
      type: Object,
      default: function () {
        return { total: 0, details: [] };
      },
    },
    marginal_tax: {
      type: Object,
      default: function () {
        return { total: 0, details: [] };
      },
    },
  },
  data(): { [key: string]: any } {
    return {};
  },
  components: {
    IonCol,
    IonRow,
    IonGrid,
  },
  methods: {
    numberWithCommas(x: any): string {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
});
