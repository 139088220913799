
import { IonContent } from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "GraphicDetails",
  components: { IonContent },
  props: {
    details: Array,
  },
  methods: {
    numberWithCommas(x: number): string {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
});
