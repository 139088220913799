
import { IonContent } from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "Contextual Help",
  components: { IonContent },
  props: {
    text: String,
  },
});
