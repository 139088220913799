
import { defineComponent } from "vue";
import {
  IonButton,
  IonCol,
  IonRow,
  IonGrid,
  popoverController,
} from "@ionic/vue";
import GraphicDetails from "@/components/GraphicDetails.vue";

export default defineComponent({
  name: "TaxWidget",
  props: {
    independent: {
      type: Boolean,
      default: false,
    },
    title: String,
    salary_income: {
      type: Object,
      default: function () {
        return { total: 0 };
      },
    },
    total_tax_threshold: {
      type: Object,
      default: function () {
        return { total: 0 };
      },
    },
    other_allowances: {
      type: Object,
      default: function () {
        return { total: 0, details: [] };
      },
    },
    work_pension_deductions: {
      type: Object,
      default: function () {
        return { total: 0 };
      },
    },
    tax: {
      type: Number,
      default: 0,
    },
    standard_tax: {
      type: Object,
      default: function () {
        return { total: 0, details: [] };
      },
    },
    marginal_tax: {
      type: Object,
      default: function () {
        return { total: 0, details: [] };
      },
    },
    marginal_relief: {
      type: Number,
      default: 0,
    },
    second_earners: {
      type: Object,
      default: function () {
        return { total: 0 };
      },
    },
    standard_taxable_income: {
      type: Object,
      default: function () {
        return { total: 0 };
      },
    },
    marginal_taxable_income: {
      type: Object,
      default: function () {
        return { total: 0 };
      },
    },
  },
  data(): { [key: string]: any } {
    return {
      toggleMarginal: true,
    };
  },
  components: {
    IonButton,
    IonCol,
    IonRow,
    IonGrid,
  },
  watch: {
    marginal_relief(): void {
      // This is to auto switch between marginal and standard
      this.toggleMarginal = this.marginal_relief > 0 ? true : false;
    },
  },
  mounted() {
      // This is to auto switch between marginal and standard
      this.toggleMarginal = this.marginal_relief > 0 ? true : false;
  },
  computed: {
    unUsedChildAllowance(): number {
      if (
        this.other_allowances.details[1] &&
        this.other_allowances.details[1].value > 0
      ) {
        let all_deductions =
          this.total_tax_threshold.total +
          this.other_allowances.total +
          this.work_pension_deductions.total +
          this.second_earners.total;

        if (all_deductions > this.salary_income.total) {
          return all_deductions - this.salary_income.total;
        }
      }
      return 0;
    },
    marginal_deductions(): number {
      return (
        this.total_tax_threshold.total +
        this.work_pension_deductions.total +
        this.second_earners.total +
        this.other_allowances.total
      );
    },
    standard_taxable_income_split(): {
      total_taxable_income: number;
      work_pension_deductions: number;
    } {
      let totalWidth =
        this.standard_taxable_income.total + this.work_pension_deductions.total;
      let income_split =
        (this.standard_taxable_income.total / totalWidth) * 100 || 0;
      let expense_split =
        (this.work_pension_deductions.total / totalWidth) * 100 || 0;
      return {
        total_taxable_income: income_split,
        work_pension_deductions: expense_split,
      };
    },
    income_split(): {
      total_income: number;
    } {
      let earned_split =
        (this.salary_income.earned / this.salary_income.total) * 100 || 0;
      let unearned_split =
        (this.salary_income.unearned / this.salary_income.total) * 100 || 0;
      return {
        total_income: earned_split + unearned_split,
      };
    },
    deductions_split(): {
      tax_threshold_split: number;
      other_allowances_split: number;
      work_pension_deductions_split: number;
      second_earners_split: number;
      taxable_income_split: number;
    } {
      // Get all deductions
      let all_deductions =
        this.total_tax_threshold.total +
        this.other_allowances.total +
        this.work_pension_deductions.total +
        this.second_earners.total;

      let total_deductions =
        all_deductions + this.marginal_taxable_income.total;
      let tax_threshold_split =
        (this.total_tax_threshold.total / total_deductions) * 100;
      let other_allowances_split =
        (this.other_allowances.total / total_deductions) * 100;
      let work_pension_deductions_split =
        (this.work_pension_deductions.total / total_deductions) * 100;
      let second_earners_split =
        (this.second_earners.total / total_deductions) * 100;
      let taxable_income_split =
        (this.marginal_taxable_income.total / total_deductions) * 100;

      return {
        tax_threshold_split: tax_threshold_split || 0,
        other_allowances_split: other_allowances_split || 0,
        work_pension_deductions_split: work_pension_deductions_split || 0,
        second_earners_split: second_earners_split || 0,
        taxable_income_split: taxable_income_split || 0,
      };
    },
  },
  methods: {
    async showDetails(e: Event, details: any) {
      const popover = await popoverController.create({
        component: GraphicDetails,
        cssClass: "graphic-details",
        componentProps: { details },
        event: e,
        translucent: true,
      });
      await popover.present();

      await popover.onDidDismiss();
    },
    numberWithCommas(x: any): string {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
});
